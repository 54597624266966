import React from "react";
import { Navigate } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import Filiais from "../pages/Filiais";
import Clientes from "../pages/Clientes";
import EditarCliente from "../pages/Clientes/EditarCliente";
import Segmentos from "../pages/Segmentos";
import Expedientes from "../pages/Expedientes";
import Marcas from "../pages/Marcas";
import Categorias from "../pages/Categorias";
import Fornecedores from "../pages/Fornecedores";
import Medidas from "../pages/Medidas";
import Itens from "../pages/Itens";
import Inventario from "../pages/Inventario";
import EntradaSaidaEstoque from "../pages/EntradaSaidaEstoque";
import Servicos from "../pages/Servicos";
import PlanosPagamentos from "../pages/PlanosPagamentos";
import Planos from "../pages/Planos";
import Pacotes from "../pages/Pacotes";
import PacotesPagamentos from "../pages/PacotesPagamentos";
import Funcoes from "../pages/Funcoes";
import Usuarios from "../pages/Usuarios";
import NovoUsuario from "../pages/Usuarios/NovoUsuario";
import FormasPagamento from "../pages/FormasPagamento";
import OrdensServico from "../pages/OrdensServico";
import NovaOs from "../pages/OrdensServico/NovaOs";
import EditarOS from "../pages/OrdensServico/EditarOs";
import Logs from "../pages/Logs";
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";

const authProtectedRoutes = [
  { path: "/principal", component: <Dashboard /> },
  { path: "/clientes", component: <Clientes /> },
  { path: "/editar-cliente/:cliente_id", component: <EditarCliente /> },
  { path: "/filiais", component: <Filiais /> },
  { path: "/segmentos", component: <Segmentos /> },
  { path: "/nova-os", component: <NovaOs /> },
  { path: "/expedientes", component: <Expedientes /> },
  { path: "/marcas-itens", component: <Marcas /> },
  { path: "/categorias-itens", component: <Categorias /> },
  { path: "/medidas-itens", component: <Medidas /> },
  { path: "/itens", component: <Itens /> },
  { path: "/fornecedores", component: <Fornecedores /> },
  { path: "/entrada-saida-estoque", component: <EntradaSaidaEstoque /> },
  { path: "/inventario", component: <Inventario /> },
  { path: "/servicos", component: <Servicos /> },
  { path: "/planos", component: <Planos /> },
  { path: "/planos-pagamentos", component: <PlanosPagamentos /> },
  { path: "/pacotes", component: <Pacotes /> },
  { path: "/pacotes-pagamentos", component: <PacotesPagamentos /> },
  { path: "/funcoes", component: <Funcoes /> },
  { path: "/usuarios", component: <Usuarios /> },
  { path: "/novo-usuario", component: <NovoUsuario /> },
  { path: "/formas-pagamento", component: <FormasPagamento /> },
  { path: "/ordens-servico", component: <OrdensServico /> },
  { path: "/ordens-servico/editar/:id", component: <EditarOS /> },
  { path: "/logs", component: <Logs /> },
  { path: "/index", component: <Dashboard /> },

  //User Profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/principal" />,
  },
  { path: "*", component: <Basic404 /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },

  { path: "/pagina-nao-encontrada", component: <Basic404 /> },
];

export { authProtectedRoutes, publicRoutes };
