import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  checkLastLogin,
  formatCurrency,
} from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import Select from "react-select";
import { NumericFormat } from "react-number-format";
import FeatherIcon from "feather-icons-react";

const apiClient = new APIClient();

const EditarOs = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [descricao, setDescricao] = useState("");
  const [statusOrdem, setStatusOrdem] = useState("novo");
  const [desconto, setDesconto] = useState(0);
  const [totalDescontado, setTotalDescontado] = useState(0);
  const [total, setTotal] = useState("");
  const [cliente, setCliente] = useState({});

  const [servicosAdicionais, setServicosAdicionais] = useState([]);
  const [descricaoAdicional, setDescricaoAdicional] = useState("");
  const [valorAdicional, setValorAdicional] = useState("");

  const [servicosSelecionados, setServicosSelecionados] = useState([]);
  const [servico, setServico] = useState({});
  const [formaPagamento, setFormaPagamento] = useState({});

  const [optionsFormasPagamento, setOptionsFormasPagamento] = useState([]);
  const [optionsServicos, setOptionsServicos] = useState([]);
  const [filial, setFilial] = useState(null);

  const [placa, setPlaca] = useState("");
  const [marca, setMarca] = useState("");
  const [modelo, setModelo] = useState("");
  const [submodelo, setSubmodelo] = useState("");
  const [situacao, setSituacao] = useState("");
  const [versao, setVersao] = useState("");
  const [cor, setCor] = useState("");
  const [ano, setAno] = useState("");
  const [anoModelo, setAnoModelo] = useState("");
  const [origem, setOrigem] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [uf, setUf] = useState("");
  const [logo, setLogo] = useState("");

  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [perfil, setPerfil] = useState("fisica");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [complemento, setComplemento] = useState("");
  const [cidade, setCidade] = useState("");
  const [ufCliente, setUfCliente] = useState("");
  const [cep, setCep] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await apiClient.get(`/ordens-servico/${id}`);
      if (response.sucesso) {
        const os = response.dados;
        setDescricao(os.descricao);
        setStatusOrdem(os.status);
        setTotal(parseFloat(os.total));
        setTotalDescontado(
          parseFloat(os.total) -
            (parseFloat(os.total) * parseFloat(os.desconto || 0)) / 100
        );
        setDesconto(os.desconto);
        setServicosSelecionados(
          os.servicos?.map((s) => ({
            value: s.id,
            label: s.servico,
            preco: parseFloat(s.preco),
          })) || []
        );
        setServicosAdicionais(os.servicos_adicionais || []);
        setFormaPagamento(
          os.forma_pagamento
            ? {
                value: os.forma_pagamento.id,
                label: os.forma_pagamento.nome,
              }
            : {}
        );
        setFilial(os.filial);

        // Set vehicle data
        if (os.veiculo) {
          setPlaca(os.veiculo.placa);
          setMarca(os.veiculo.marca);
          setModelo(os.veiculo.modelo);
          setSubmodelo(os.veiculo.submodelo);
          setSituacao(os.veiculo.situacao);
          setVersao(os.veiculo.versao);
          setCor(os.veiculo.cor);
          setAno(os.veiculo.ano);
          setAnoModelo(os.veiculo.ano_modelo);
          setOrigem(os.veiculo.origem);
          setMunicipio(os.veiculo.municipio);
          setUf(os.veiculo.uf);
          setLogo(os.veiculo.logo);
        }

        // Set client data
        if (os.cliente) {
          setCliente(os.cliente);
          setNome(os.cliente.nome);
          setCpf(os.cliente.cpf);
          setCnpj(os.cliente.cnpj);
          setEmail(os.cliente.email);
          setTelefone(os.cliente.telefone);
          setPerfil(os.cliente.perfil);
          setEndereco(os.cliente.endereco);
          setNumero(os.cliente.numero);
          setBairro(os.cliente.bairro);
          setComplemento(os.cliente.complemento);
          setCidade(os.cliente.cidade);
          setUfCliente(os.cliente.uf);
          setCep(os.cliente.cep);
        }
      } else {
        toast.error(response.mensagem);
      }
      await fetchOptionsData();
    } catch (error) {
      toast.error("Erro ao carregar dados da Ordem de Serviço");
    } finally {
      setLoading(false);
    }
  };

  const fetchOptionsData = async () => {
    try {
      const [formasPagamentoRes, servicosRes] = await Promise.all([
        apiClient.get("/formas-pagamento"),
        apiClient.get("/servicos"),
      ]);

      if (formasPagamentoRes.sucesso) {
        setOptionsFormasPagamento(
          formasPagamentoRes.dados.map((fp) => ({
            value: fp.id,
            label: fp.nome,
          }))
        );
      }

      if (servicosRes.sucesso) {
        setOptionsServicos(
          servicosRes.dados.map((s) => ({
            value: s.id,
            label: s.servico,
            preco: parseFloat(s.preco),
          }))
        );
      } else {
        toast.error(servicosRes.mensagem);
      }
    } catch (error) {
      toast.error("Erro ao carregar dados");
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const data = {
        filial_id: filial?.id,
        descricao,
        desconto: desconto === "" ? 0 : desconto,
        status: statusOrdem,
        total: total === "" ? 0 : total,
        forma_pagamento_id: formaPagamento.value,
        servicos_ids: servicosSelecionados.map((servico) => servico.value),
        servicos_adicionais: servicosAdicionais,
      };

      const response = await apiClient.put(`/ordens-servico/${id}`, data);
      if (response.sucesso) {
        toast.success(response.mensagem);
        localStorage.removeItem("lastUpdated");
        navigate("/ordens-servico");
      } else {
        console.error(response.mensagem);
        if (response.mensagem !== "") toast.error(response.mensagem);
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }
    } catch (error) {
      toast.error("Erro ao editar Ordem de Serviço");
    } finally {
      setLoading(false);
    }
  };

  const addServicoAdicional = () => {
    if (
      !valorAdicional ||
      valorAdicional === "" ||
      valorAdicional === "0" ||
      parseFloat(valorAdicional) === 0
    ) {
      toast.error("Valor de serviço adicional é obrigatório");
      return;
    }
    if (!descricaoAdicional || descricaoAdicional === "") {
      toast.error("Descrição de serviço adicional é obrigatório");
      return;
    }
    const servicoAdicionado = {
      descricao: descricaoAdicional,
      preco: parseFloat(valorAdicional === "" ? 0 : valorAdicional),
      index: servicosAdicionais.length,
    };

    //adiciona ao total e totalDescontado
    const descontoOS = (desconto === "" ? 0 : parseFloat(desconto)) / 100;
    const _total = total + servicoAdicionado.preco;
    const totalCalculado = _total - _total * descontoOS;
    setTotal(_total);
    setTotalDescontado(totalCalculado);

    const servicos = [...servicosAdicionais, servicoAdicionado];
    setServicosAdicionais(servicos);
    setDescricaoAdicional("");
    setValorAdicional("");
  };

  const removeServicoAdicional = (index) => {
    const servicoRemovido = servicosAdicionais.find(s => s.index === index);
    if (!servicoRemovido) return;
    
    const servicos = servicosAdicionais.filter(s => s.index !== index);

    //remove do total e totalDescontado
    let descontoOS = desconto === "" ? 0 : parseFloat(desconto);
    const _total = total - servicoRemovido.preco;
    const totalCalculado = _total - _total * descontoOS;
    setTotal(_total);
    setTotalDescontado(totalCalculado);

    setServicosAdicionais(servicos);
  };

  const calcularTotalAdd = (servicosIdsPlano, servico) => {
    const qtdPlano = (servicosIdsPlano || []).filter(
      (serv) => parseInt(serv) === parseInt(servico.value)
    ).length;
    const descontoPlano = servico.preco * qtdPlano;

    let totalAnteriorDeIncidenciasDesteServico = servicosSelecionados.filter(
      (serv) => parseInt(serv.value) === parseInt(servico.value)
    ).length;

    let totalAnteriorDesteServico =
      servico.preco * totalAnteriorDeIncidenciasDesteServico - descontoPlano;
    totalAnteriorDesteServico =
      totalAnteriorDesteServico < 0 ? 0 : totalAnteriorDesteServico;

    const newServicos = [...servicosSelecionados, servico];

    const totalDeIncidenciasDesteServico = newServicos.filter(
      (serv) => parseInt(serv.value) === parseInt(servico.value)
    ).length;

    let totalDesteServico =
      servico.preco * totalDeIncidenciasDesteServico - descontoPlano;
    totalDesteServico = totalDesteServico < 0 ? 0 : totalDesteServico;

    const descontoOS = (desconto === "" ? 0 : parseFloat(desconto)) / 100;
    let totalCalculado = total - totalAnteriorDesteServico + totalDesteServico;
    totalCalculado = totalCalculado < 0 ? 0 : totalCalculado;

    setTotal(totalCalculado);
    setTotalDescontado(totalCalculado - totalCalculado * descontoOS);

    setServicosSelecionados(newServicos);
  };

  const calcularTotalRemover = (servicosIdsPlano, index) => {
    const servicoRemovido = servicosSelecionados[index];
    const newServicos = [...servicosSelecionados];
    newServicos.splice(index, 1);

    const qtdPlano = (servicosIdsPlano || []).filter(
      (serv) => parseInt(serv) === parseInt(servicoRemovido.value)
    ).length;
    const descontoPlano = servicoRemovido.preco * qtdPlano;

    let totalAnteriorDeIncidenciasDesteServico = servicosSelecionados.filter(
      (serv) => parseInt(serv.value) === parseInt(servicoRemovido.value)
    ).length;

    let totalAnteriorDesteServico =
      servicoRemovido.preco * totalAnteriorDeIncidenciasDesteServico -
      descontoPlano;
    totalAnteriorDesteServico =
      totalAnteriorDesteServico < 0 ? 0 : totalAnteriorDesteServico;

    const totalDeIncidenciasDesteServico = newServicos.filter(
      (serv) => parseInt(serv.value) === parseInt(servicoRemovido.value)
    ).length;

    let totalDesteServico =
      servicoRemovido.preco * totalDeIncidenciasDesteServico - descontoPlano;
    totalDesteServico = totalDesteServico < 0 ? 0 : totalDesteServico;

    const descontoOS = (desconto === "" ? 0 : parseFloat(desconto)) / 100;
    let totalCalculado = total - totalAnteriorDesteServico + totalDesteServico;
    totalCalculado = totalCalculado < 0 ? 0 : totalCalculado;

    setTotal(totalCalculado);
    setTotalDescontado(totalCalculado - totalCalculado * descontoOS);

    setServicosSelecionados(newServicos);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title="Editar Ordem de Serviço"
          pageTitle="Ordens de Serviço"
        />
        <Row>
          <Col md={8}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">
                  Detalhes da Ordem de Serviço
                </h4>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className="text-center">
                    <Spinner color="primary" />
                  </div>
                ) : (
                  <form>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="statusOrdem" className="form-label">
                            Status
                          </Label>
                          <Input
                            type="select"
                            id="statusOrdem"
                            value={statusOrdem}
                            onChange={(e) => setStatusOrdem(e.target.value)}
                          >
                            <option value="novo">Novo</option>
                            <option value="em-andamento">Em Andamento</option>
                            <option value="concluido">Concluído</option>
                            <option value="cancelado">Cancelado</option>
                          </Input>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="formaPagamento"
                            className="form-label"
                          >
                            Forma de Pagamento
                          </Label>
                          <Select
                            id="formaPagamento"
                            value={optionsFormasPagamento.find(
                              (op) => op.value === formaPagamento?.value
                            )}
                            onChange={(selected) => setFormaPagamento(selected)}
                            options={optionsFormasPagamento}
                            placeholder="Selecione..."
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label htmlFor="descricao" className="form-label">
                            Descrição
                          </Label>
                          <Input
                            type="textarea"
                            id="descricao"
                            rows="3"
                            value={descricao || ""}
                            onChange={(e) => setDescricao(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="desconto" className="form-label">
                            Desconto
                          </Label>
                          <NumericFormat
                            id="desconto"
                            className="form-control"
                            value={desconto}
                            onValueChange={(values) => {
                              const value = values.value;
                              setDesconto(value);
                              setTotalDescontado(
                                total *
                                  (1 -
                                    parseFloat(value === "" ? "0" : value) /
                                      100)
                              );
                            }}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            suffix=" %"
                            fixedDecimalScale={true}
                            allowNegative={false}
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <Label className="form-label">Planos do Cliente</Label>
                        <Row className="mt-1">
                          <Col md={12}>
                            {cliente?.planos &&
                            cliente?.planos?.length === 0 ? (
                              <p className="badge bg-light text-dark me-1">
                                Cliente não possui planos ativos
                              </p>
                            ) : (
                              cliente?.planos?.map((plano, index) => (
                                <p
                                  key={index}
                                  className="badge bg-light text-dark me-1"
                                >
                                  {plano?.nome}
                                </p>
                              ))
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col md={9}>
                        <Label htmlFor="servicos" className="form-label">
                          Serviços
                        </Label>
                        <Select
                          value={servico}
                          onChange={(sortBy) => {
                            setServico(sortBy);
                          }}
                          options={optionsServicos}
                          id="servicos"
                          placeholder={
                            loading ? "Carregando..." : "Selecione o serviço"
                          }
                          disabled={loading}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              border: "1px solid #ced4da",
                              color: "#aaa",
                            }),
                            input: (provided) => ({
                              ...provided,
                              color: "#aaa",
                            }),
                          }}
                        />
                      </Col>
                      <Col md={3} className="pt-2">
                        <button
                          className="btn btn-sm btn-success mt-4"
                          onClick={(e) => {
                            e.preventDefault();
                            if (servico?.value) {
                              calcularTotalAdd(cliente?.servicos, servico);
                              setServico({});
                            }
                          }}
                        >
                          Adicionar
                        </button>
                      </Col>
                    </Row>

                    <Row className="mt-3 border-top pt-3">
                      <h4>Serviços Adicionais</h4>
                      <Col md={5}>
                        <Label for="descricao" className="form-label">
                          Descrição
                        </Label>
                        <Input
                          type="text"
                          id="descricao"
                          name="descricao"
                          value={descricaoAdicional}
                          onChange={(e) =>
                            setDescricaoAdicional(e.target.value)
                          }
                        />
                      </Col>
                      <Col md={5}>
                        <Label for="valorAdicional" className="form-label">
                          Valor
                        </Label>
                        <NumericFormat
                          id="valorAdicional"
                          placeholder="R$ 0,00"
                          className="form-control"
                          value={valorAdicional || ""}
                          onValueChange={({ value }) =>
                            setValorAdicional(value)
                          }
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale={2}
                          prefix="R$ "
                          fixedDecimalScale={true}
                          allowNegative={false}
                        />
                      </Col>
                      <Col md={2} className="pt-2">
                        <button
                          className="btn btn-sm btn-success mt-4"
                          onClick={(e) => {
                            e.preventDefault();
                            addServicoAdicional();
                          }}
                        >
                          Adicionar
                        </button>
                      </Col>
                    </Row>

                    <Row className="mt-3 border-top pt-3">
                      <Col md={12}>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>Serviço</th>
                              <th>Preço</th>
                              <th>Remover</th>
                            </tr>
                          </thead>
                          <tbody>
                            {servicosSelecionados.map((servico, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    overflowWrap: "break-word",
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {servico.label}
                                </td>
                                <td>{formatCurrency(servico.preco)}</td>
                                <td>
                                  <button
                                    className="btn btn-sm btn-danger"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      calcularTotalRemover(
                                        cliente?.servicos,
                                        index
                                      );
                                    }}
                                  >
                                    Remover
                                  </button>
                                </td>
                              </tr>
                            ))}
                            {servicosAdicionais.map((servico, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    overflowWrap: "break-word",
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {servico.descricao}
                                </td>
                                <td>{formatCurrency(servico.preco)}</td>
                                <td>
                                  <button
                                    className="btn btn-sm btn-danger"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      removeServicoAdicional(servico.index);
                                    }}
                                  >
                                    Remover
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="total" className="form-label">
                            Total
                          </Label>
                          <NumericFormat
                            id="total"
                            className="form-control"
                            value={total}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="R$ "
                            decimalScale={2}
                            fixedDecimalScale
                            readOnly
                          />
                        </div>
                      </Col>
                    </Row>

                    <div className="text-end mt-4">
                      <Button
                        type="button"
                        color="primary"
                        onClick={handleSubmit}
                      >
                        Salvar Alterações
                      </Button>
                    </div>
                  </form>
                )}
              </CardBody>
            </Card>
          </Col>

          <Col md={4}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">Informações do Veículo</h4>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <Label className="form-label">Placa</Label>
                    <Input value={placa || ""} readOnly />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>
                    <Label className="form-label">Marca</Label>
                    <Input value={marca || ""} readOnly />
                  </Col>
                  <Col md={6}>
                    <Label className="form-label">Modelo</Label>
                    <Input value={modelo || ""} readOnly />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>
                    <Label className="form-label">Cor</Label>
                    <Input value={cor || ""} readOnly />
                  </Col>
                  <Col md={6}>
                    <Label className="form-label">Ano/Modelo</Label>
                    <Input value={`${ano || ""}/${anoModelo || ""}`} readOnly />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={12}>
                    <Label className="form-label">Situação</Label>
                    <Input value={situacao || ""} readOnly />
                  </Col>
                </Row>
                {logo && (
                  <Row className="mt-3">
                    <Col md={12} className="text-center">
                      <img
                        src={logo}
                        alt="Logo"
                        className="img-fluid"
                        style={{ maxHeight: "100px" }}
                      />
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>

            <Card className="mt-4">
              <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">
                  Informações do Cliente
                </h4>
                {telefone && (
                  <div className="flex-shrink-0">
                    <Link
                      to={`https://wa.me/55${telefone.replace(
                        /\D/g,
                        ""
                      )}?text=`}
                      target="_blank"
                      className="btn btn-sm btn-success"
                    >
                      <FeatherIcon icon="message-square" size={16} /> Falar com
                      Cliente
                    </Link>
                  </div>
                )}
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <Label className="form-label">Nome</Label>
                    <Input value={nome || ""} readOnly />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={12}>
                    <Label className="form-label">
                      {perfil === "fisica" ? "CPF" : "CNPJ"}
                    </Label>
                    <Input
                      value={perfil === "fisica" ? cpf || "" : cnpj || ""}
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={12}>
                    <Label className="form-label">Telefone</Label>
                    <Input value={telefone || ""} readOnly />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={12}>
                    <Label className="form-label">Email</Label>
                    <Input value={email || ""} readOnly />
                  </Col>
                </Row>
                {endereco && (
                  <Row className="mt-3">
                    <Col md={12}>
                      <Label className="form-label">Endereço</Label>
                      <Input
                        value={`${endereco}, ${numero} - ${bairro}, ${cidade}/${ufCliente}`}
                        readOnly
                      />
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EditarOs;
