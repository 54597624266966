import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  checkLastLogin,
  formatCurrency,
} from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import Select from "react-select";
import { NumericFormat } from "react-number-format";
const apiClient = new APIClient();

const ModalEditar = ({
  modal,
  setModal,
  setRefresh,
  ordemServico,
  optionsFormasPagamento,
  optionsServicos,
  filial
}) => {
  const [loading, setLoading] = useState(true);
  const [descricao, setDescricao] = useState("");
  const [statusOrdem, setStatusOrdem] = useState("novo");
  const [desconto, setDesconto] = useState(0);
  const [totalDescontado, setTotalDescontado] = useState(0);
  const [total, setTotal] = useState("");
  const [cliente, setCliente] = useState({});

  const [servicosAdicionais, setServicosAdicionais] = useState([]);
  const [descricaoAdicional, setDescricaoAdicional] = useState("");
  const [valorAdicional, setValorAdicional] = useState("");

  const [servicosSelecionados, setServicosSelecionados] = useState([]);
  const [servico, setServico] = useState({});
  const [formaPagamento, setFormaPagamento] = useState({});

  const fetchClienteDadosId = async (id) => {
    setLoading(true);
    const response = await apiClient.get(
      `/clientes/${id}`
    );
    if (response.sucesso) {
      const dados = response.dados;
      setCliente(dados);
    }
    setLoading(false);
  };

  const toggle = () => {
    setDescricao("");
    setStatusOrdem("novo");
    setTotal("");
    setTotalDescontado(0);
    setDesconto(0);
    setServicosSelecionados([]);
    setServico({});
    setFormaPagamento({});
    setCliente({});
    setServicosAdicionais([]);
    setDescricaoAdicional("");
    setValorAdicional("");

    setModal(!modal);
  };

  const addServicoAdicional = () => {
    if (
      !valorAdicional ||
      valorAdicional === "" ||
      valorAdicional === "0" ||
      parseFloat(valorAdicional) === 0
    ) {
      toast.error("Valor de serviço adicional é obrigatório");
      return;
    }
    if (!descricaoAdicional || descricaoAdicional === "") {
      toast.error("Descrição de serviço adicional é obrigatório");
      return;
    }
    const servicoAdicionado = {
      descricao: descricaoAdicional,
      preco: parseFloat(valorAdicional === "" ? 0 : valorAdicional),
      index: servicosAdicionais.length,
    };

    //adiciona ao total e totalDescontado
    const descontoOS = (desconto === "" ? 0 : parseFloat(desconto)) / 100;
    const _total = total + servicoAdicionado.preco;
    const totalCalculado = _total - _total * descontoOS;
    setTotal(_total);
    setTotalDescontado(totalCalculado);

    const servicos = [...servicosAdicionais, servicoAdicionado];
    setServicosAdicionais(servicos);
    setDescricaoAdicional("");
    setValorAdicional("");
  };

  const removeServicoAdicional = (index) => {
    const servicoRemovido = servicosAdicionais[index];
    const servicos = servicosAdicionais.filter((serv, i) => i !== index);

    //remove do total e totalDescontado
    let descontoOS = desconto === "" ? 0 : parseFloat(desconto);
    const _total = total - servicoRemovido.preco;
    const totalCalculado = _total - _total * descontoOS;
    setTotal(_total);
    setTotalDescontado(totalCalculado);

    setServicosAdicionais(servicos);
  };

  const editar = async (ordemServico) => {
    setLoading(true);

    const data = {
      filial_id: filial?.id,
      descricao,
      desconto: desconto === "" ? 0 : desconto,
      status: statusOrdem,
      total: total === "" ? 0 : total,
      forma_pagamento_id: formaPagamento.value,
      servicos_ids: servicosSelecionados.map((servico) => servico.value),
      servicos_adicionais: servicosAdicionais,
    };

    const response = await apiClient.put(
      `/ordens-servico/${ordemServico.id}`,
      data
    );
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  const calcularTotalAdd = (servicosIdsPlano, servico) => {
    const qtdPlano = servicosIdsPlano.filter(
      (serv) => parseInt(serv) === parseInt(servico.value)
    ).length;
    const descontoPlano = servico.preco * qtdPlano;

    let totalAnteriorDeIncidenciasDesteServico = servicosSelecionados.filter(
      (serv) => parseInt(serv.value) === parseInt(servico.value)
    ).length;

    let totalAnteriorDesteServico =
      servico.preco * totalAnteriorDeIncidenciasDesteServico - descontoPlano;
    totalAnteriorDesteServico =
      totalAnteriorDesteServico < 0 ? 0 : totalAnteriorDesteServico;

    const newServicos = [...servicosSelecionados, servico];

    const totalDeIncidenciasDesteServico = newServicos.filter(
      (serv) => parseInt(serv.value) === parseInt(servico.value)
    ).length;

    let totalDesteServico =
      servico.preco * totalDeIncidenciasDesteServico - descontoPlano;
    totalDesteServico = totalDesteServico < 0 ? 0 : totalDesteServico;

    const descontoOS = (desconto === "" ? 0 : parseFloat(desconto)) / 100;
    let totalCalculado = total - totalAnteriorDesteServico + totalDesteServico;
    totalCalculado = totalCalculado < 0 ? 0 : totalCalculado;

    setTotal(totalCalculado);
    setTotalDescontado(totalCalculado - totalCalculado * descontoOS);

    setServicosSelecionados(newServicos);
  };

  const calcularTotalRemover = (servicosIdsPlano, index) => {
    const newServicos = [...servicosSelecionados];
    const servicoRemovido = newServicos.splice(index, 1)[0];

    const qtdPlano = servicosIdsPlano.filter(
      (serv) => parseInt(serv) === parseInt(servicoRemovido.value)
    ).length;
    const descontoPlano = servicoRemovido.preco * qtdPlano;

    let totalAnteriorDeIncidenciasDesteServico = servicosSelecionados.filter(
      (serv) => parseInt(serv.value) === parseInt(servicoRemovido.value)
    ).length;

    let totalAnteriorDesteServico =
      servicoRemovido.preco * totalAnteriorDeIncidenciasDesteServico -
      descontoPlano;
    totalAnteriorDesteServico =
      totalAnteriorDesteServico < 0 ? 0 : totalAnteriorDesteServico;

    const totalDeIncidenciasDesteServico = newServicos.filter(
      (serv) => parseInt(serv.value) === parseInt(servicoRemovido.value)
    ).length;
    let totalDesteServico =
      servicoRemovido.preco * totalDeIncidenciasDesteServico - descontoPlano;
    totalDesteServico = totalDesteServico < 0 ? 0 : totalDesteServico;

    const descontoOS = (desconto === "" ? 0 : parseFloat(desconto)) / 100;
    let totalCalculado = total - totalAnteriorDesteServico + totalDesteServico;
    totalCalculado = totalCalculado < 0 ? 0 : totalCalculado;
    setTotal(totalCalculado);
    setTotalDescontado(totalCalculado - totalCalculado * descontoOS);
    setServicosSelecionados(newServicos);
  };

  useEffect(() => {
    const fetch = async () => {
      setDescricao(ordemServico.descricao);
      setStatusOrdem(ordemServico.status);
      setTotal(parseFloat(ordemServico.total));
      setDesconto(ordemServico.desconto);
      const descontoOrdem = 1 - desconto / 100;
      setTotalDescontado(ordemServico.total * descontoOrdem);
      setFormaPagamento({
        value: ordemServico.forma_pagamento?.id,
        label: ordemServico.forma_pagamento?.nome,
      });

      const servicos = ordemServico.servicos.map((servico) => ({
        value: servico.id,
        label: servico.servico,
        preco: servico.preco,
      }));

      const _servicosAdicionais = ordemServico.servicos_adicionais.map(
        (servico, index) => ({
          descricao: servico.descricao,
          preco: servico.preco,
          index,
        })
      );

      setServicosSelecionados(servicos);
      setServicosAdicionais(_servicosAdicionais);

      await fetchClienteDadosId(ordemServico.cliente_id);
      setLoading(false);
    };
    if (modal && ordemServico) {
      fetch();
    }
  }, [modal, ordemServico]);
  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Editar OS #{ordemServico?.id}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <Label for="data_realizado" className="form-label">
                Data Realizado
              </Label>
              <Input
                id="data_realizado"
                className="form-control"
                type="date"
                value={ordemServico?.data_realizado || ""}
                onChange={(e) => {}}
                readOnly
              />
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="statusOrdem" className="form-label">
                  Status
                </Label>
                <Input
                  id="statusOrdem"
                  className="form-control"
                  type="select"
                  value={statusOrdem}
                  onChange={(e) => setStatusOrdem(e.target.value)}
                >
                  <option value="novo">Novo</option>
                  <option value="pago">Pago</option>
                  <option value="em-andamento">Em Andamento</option>
                  <option value="inadimplente">Inadimplente</option>
                  <option value="finalizada">Finalizado</option>
                </Input>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="descricao" className="form-label">
                  Observação
                </Label>
                <Input
                  id="descricao"
                  className="form-control"
                  type="textarea"
                  value={descricao}
                  onChange={(e) => setDescricao(e.target.value)}
                  maxLength={255}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <Label for="formaPagamento" className="form-label">
                Forma de Pagamento
              </Label>
              <Select
                value={formaPagamento}
                onChange={(sortBy) => {
                  setFormaPagamento(sortBy);
                }}
                options={optionsFormasPagamento}
                id="formaPagamento"
                placeholder={
                  loading ? "Carregando..." : "Selecione a forma de pagamento"
                }
                disabled={loading}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "1px solid #ced4da",
                    color: "#aaa",
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: "#aaa",
                  }),
                }}
              />
            </Col>
            <Col md="6">
              <Label for="total" className="form-label">
                Total
              </Label>
              <NumericFormat
                id="total"
                placeholder="R$ 0,00"
                className="form-control"
                value={totalDescontado}
                onValueChange={() => {}}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                prefix="R$ "
                fixedDecimalScale={true}
                allowNegative={false}
                readOnly
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <Label for="desconto" className="form-label">
                Desconto
              </Label>
              <NumericFormat
                id="desconto"
                placeholder="0,00 %"
                className="form-control"
                value={desconto || ""}
                onValueChange={({ value }) => {
                  setDesconto(value);
                  setTotalDescontado(
                    total * (1 - parseFloat(value === "" ? "0" : value) / 100)
                  );
                }}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                suffix=" %"
                fixedDecimalScale={true}
                allowNegative={false}
              />
            </Col>

            <Col md="6">
              <Label className="form-label">Planos do Cliente</Label>
              <Row className="mt-1">
                <Col md="12">
                  {cliente?.planos && cliente?.planos?.lenght === 0 ? (
                    <p className="badge bg-light text-dark me-1">
                      Cliente não possui planos ativos
                    </p>
                  ) : (
                    cliente?.planos?.map((plano, index) => (
                      <p key={index} className="badge bg-light text-dark me-1">
                        {plano?.nome}
                      </p>
                    ))
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="9">
              <Label htmlFor="servicos" className="form-label">
                Serviços
              </Label>
              <Select
                value={servico}
                onChange={(sortBy) => {
                  setServico(sortBy);
                }}
                options={optionsServicos}
                id="servicos"
                placeholder={loading ? "Carregando..." : "Selecione o serviço"}
                disabled={loading}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "1px solid #ced4da",
                    color: "#aaa",
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: "#aaa",
                  }),
                }}
              />
            </Col>
            <Col md="3" className="pt-2">
              <button
                className="btn btn-sm btn-success mt-4"
                onClick={() => {
                  if (servico?.value) {
                    calcularTotalAdd(cliente?.servicos, servico);
                    setServico({});
                  }
                }}
              >
                Adicionar
              </button>
            </Col>
          </Row>

          <Row className="mt-3 border-top pt-3">
            <h4>Serviços Adicionais</h4>
            <Col md="5">
              <Label for="descricao" className="form-label">
                Descrição
              </Label>
              <Input
                type="text"
                id="descricao"
                name="descricao"
                value={descricaoAdicional}
                onChange={(e) => setDescricaoAdicional(e.target.value)}
              />
            </Col>
            <Col md="5">
              <Label for="valorAdicional" className="form-label">
                Valor
              </Label>
              <NumericFormat
                id="valorAdicional"
                placeholder="R$ 0,00"
                className="form-control"
                value={valorAdicional || ""}
                onValueChange={({ value }) => setValorAdicional(value)}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                prefix="R$ "
                fixedDecimalScale={true}
                allowNegative={false}
              />
            </Col>
            <Col md="2" className="pt-2">
              <button
                className="btn btn-sm btn-success mt-4"
                onClick={() => {
                  addServicoAdicional();
                }}
              >
                Adicionar
              </button>
            </Col>
          </Row>

          <Row className="mt-3 border-top pt-3">
            <Col md="12">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Serviço</th>
                    <th>Preço</th>
                    <th>Remover</th>
                  </tr>
                </thead>
                <tbody>
                  {servicosSelecionados.map((servico, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          overflowWrap: "break-word",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {servico.label}
                      </td>
                      <td>{formatCurrency(servico.preco)}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => {
                            calcularTotalRemover(cliente?.servicos, index);
                          }}
                        >
                          Remover
                        </button>
                      </td>
                    </tr>
                  ))}
                  {servicosAdicionais.map((servico, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          overflowWrap: "break-word",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {servico.descricao}
                      </td>
                      <td>{formatCurrency(servico.preco)}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => {
                            removeServicoAdicional(servico.index);
                          }}
                        >
                          Remover
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>

          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-success fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Fechar
            </Link>
            <button
              className="btn btn-success"
              onClick={() => editar(ordemServico)}
              disabled={loading}
            >
              Editar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
