import React, { useState, Fragment, useCallback, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Label,
  Input,
  Progress,
  Button,
  Spinner,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import SearchOption from "../../Components/Common/SearchOption";
import {
  checkLastLogin,
  formatCurrency,
  nomeMask,
  formatEmail,
  converterData,
} from "../../Components/FunctionsRepository";
import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
import Select from "react-select";
import { NumericFormat } from "react-number-format";
const apiClient = new APIClient();

const ModalNovo = () => {
  const [loading, setLoading] = useState(true);
  const [etapa, setEtapa] = useState(1);
  const [cliente, setCliente] = useState(null);
  const [veiculo, setVeiculo] = useState(null);
  const [descricao, setDescricao] = useState("");
  const [dataRealizado, setDataRealizado] = useState("");
  const [total, setTotal] = useState(0);
  const [totalDescontado, setTotalDescontado] = useState(0);
  const [desconto, setDesconto] = useState("");

  const [optionsFiliais, setOptionsFiliais] = useState([]);
  const [filial, setFilial] = useState({});
  const [optionsFormasPagamento, setOptionsFormasPagamento] = useState([]);
  const [formaPagamento, setFormaPagamento] = useState({});
  const [optionsExpedientes, setOptionsExpedientes] = useState([]);
  const [expediente, setExpediente] = useState(null);
  const [optionsServicos, setOptionsServicos] = useState([]);

  const [placa, setPlaca] = useState("");
  const [marca, setMarca] = useState("");
  const [modelo, setModelo] = useState("");
  const [submodelo, setSubmodelo] = useState("");
  const [situacao, setSituacao] = useState("");
  const [versao, setVersao] = useState("");
  const [cor, setCor] = useState("");
  const [ano, setAno] = useState("");
  const [anoModelo, setAnoModelo] = useState("");
  const [origem, setOrigem] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [uf, setUf] = useState("");
  const [logo, setLogo] = useState("");

  const [nome, setNome] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [perfil, setPerfil] = useState("fisica");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [complemento, setComplemento] = useState("");
  const [cidade, setCidade] = useState("");
  const [ufCliente, setUfCliente] = useState("");
  const [cep, setCep] = useState("");
  const [statusOrdem, setStatusOrdem] = useState("novo");

  const [servicosSelecionados, setServicosSelecionados] = useState([]);
  const [servico, setServico] = useState({});

  const [servicosAdicionais, setServicosAdicionais] = useState([]);
  const [descricaoAdicional, setDescricaoAdicional] = useState("");
  const [valorAdicional, setValorAdicional] = useState("");

  const [termoBusca, setTermoBusca] = useState("");
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [loadTable, setLoadTable] = useState(false);
  const [clientes, setClientes] = useState([]);

  const quantidadeEmPlano = (servicoAdicionado, servicos) => {
    const emPlano = servicos.filter((serv) => {
      return parseInt(serv) === parseInt(servicoAdicionado.value);
    }).length;
    return emPlano;
  };

  const chooseCliente = (cliente) => {
    setCliente(cliente);
    setNome(cliente.nome);
    setPerfil(cliente.perfil);
    setCpf(cliente.cpf_cnpj);
    setCnpj(cliente.cpf_cnpj);
    setEmail(cliente.email);
    setTelefone(cliente.telefone);
    setCep(cliente.cep);
    setEndereco(cliente.endereco);
    setNumero(cliente.numero);
    setBairro(cliente.bairro);
    setComplemento(cliente.complemento);
    const cidadeNome = cliente.cidade?.split(" - ");
    setCidade(cliente.cidade ? cidadeNome[0] : "");
    setUfCliente(cliente.cidade ? cidadeNome[1] : "");

    setClientes([]);
    setTermoBusca("");
  };

  const addServicoAdicional = () => {
    if (
      !valorAdicional ||
      valorAdicional === "" ||
      valorAdicional === "0" ||
      parseFloat(valorAdicional) === 0
    ) {
      toast.error("Valor de serviço adicional é obrigatório");
      return;
    }
    if (!descricaoAdicional || descricaoAdicional === "") {
      toast.error("Descrição de serviço adicional é obrigatório");
      return;
    }
    const servicoAdicionado = {
      descricao: descricaoAdicional,
      preco: parseFloat(valorAdicional === "" ? 0 : valorAdicional),
      index: servicosAdicionais.length,
    };

    //adiciona ao total e totalDescontado
    const descontoOS = (desconto === "" ? 0 : parseFloat(desconto)) / 100;
    const _total = total + servicoAdicionado.preco;
    const totalCalculado = _total - _total * descontoOS;
    setTotal(_total);
    setTotalDescontado(totalCalculado);

    const servicos = [...servicosAdicionais, servicoAdicionado];
    setServicosAdicionais(servicos);
    setDescricaoAdicional("");
    setValorAdicional("");
  };

  const removeServicoAdicional = (index) => {
    const servicoRemovido = servicosAdicionais[index];
    const servicos = servicosAdicionais.filter((serv, i) => i !== index);

    //remove do total e totalDescontado
    let descontoOS = desconto === "" ? 0 : parseFloat(desconto);
    const _total = total - servicoRemovido.preco;
    const totalCalculado = _total - _total * descontoOS;
    setTotal(_total);
    setTotalDescontado(totalCalculado);

    setServicosAdicionais(servicos);
  };

  const handleCep = async (cepPesquisa) => {
    const _cep = cepPesquisa.replace(/[^0-9]/g, "");
    setCep(_cep);

    if (_cep.length !== 8) return;
    try {
      const response = await apiClient.get(`cep/${_cep}`);
      const dadosRetorno = response.dados;

      setEndereco(dadosRetorno.logradouro);
      setBairro(dadosRetorno.bairro);
      setCidade(`${dadosRetorno.localidade} - ${dadosRetorno.uf}`);
    } catch (erro) {
      console.error(erro);
      toast.error("CEP não encontrado");
    }
  };

  const formatPlaca = (placa) => {
    setPlaca(placa.replace(/[^a-zA-Z0-9]/g, "").toUpperCase());
  };

  const fetchClienteDadosId = async (id) => {
    setLoadTable(true);
    const response = await apiClient.get(`/clientes/${id}`);
    if (response.sucesso) {
      const dados = response.dados;
      chooseCliente(dados);
    }
    setLoadTable(false);
  };

  const cadastrarVeiculo = async (cliente) => {
    const dataVeiculo = {
      cliente_id: cliente.id,
      placa,
      marca,
      modelo,
      submodelo,
      versao,
      cor,
      ano,
      ano_modelo: anoModelo,
      situacao,
      origem,
      municipio,
      uf,
      logo,
    };

    const response = await apiClient.post("/veiculos", dataVeiculo);
    if (response.sucesso) {
      setVeiculo(response.dados);
      setEtapa(3);
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  };

  const fetchCarroDados = async () => {
    setLoadTable(true);
    const data = {
      placa,
      consulta_api: false,
    };

    const response = await apiClient.post("/veiculos/placa", data);
    if (response.sucesso) {
      const dados = response.dados;
      setMarca(dados.marca);
      setModelo(dados.modelo);
      setSubmodelo(dados.submodelo);
      setVersao(dados.versao);
      setCor(dados.cor);
      setAno(dados.ano);
      setAnoModelo(dados.ano_modelo);
      setSituacao(dados.situacao);
      setOrigem(dados.origem);
      setMunicipio(dados.municipio);
      setUf(dados.uf);
      setLogo(dados.logo);

      if (dados.cliente) {
        await fetchClienteDadosId(dados.cliente.id);
        await cadastrarVeiculo(dados.cliente);
        setEtapa(3);
      } else {
        setEtapa(2);
      }
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoadTable(false);
  };

  const fetchServicos = useCallback(async () => {
    const response = await apiClient.get("/servicos");
    if (response.sucesso) {
      const dados = response.dados;
      setOptionsServicos(
        dados.map((servico) => ({
          label: `${servico?.servico} (${formatCurrency(servico?.preco)})`,
          nome: `${servico?.servico}`,
          preco: `${servico?.preco}`,
          value: `${servico?.id}`,
        }))
      );
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const fetchFiliais = useCallback(async () => {
    const userData = await getLoggedinUser();
    setOptionsFiliais(
      userData.filiais.map((filial) => ({
        label: `${filial?.nome}`,
        value: `${filial?.id}`,
      }))
    );
    if (
      userData.filiais.length > 0 &&
      !filial?.value &&
      userData.role !== "super-admin"
    ) {
      setFilial({
        label: `${userData.filiais[0].nome}`,
        value: `${userData.filiais[0].id}`,
      });
    }
  }, []);

  const fetchExpedientes = useCallback(async () => {
    const response = await apiClient.get("/expedientes");
    if (response.sucesso) {
      const dados = response.dados;
      setOptionsExpedientes(
        dados.map((item) => ({
          label: `${item?.descricao}`,
          value: `${item?.id}`,
        }))
      );
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const fetchFormasPagamento = useCallback(async () => {
    const response = await apiClient.get("/formas-pagamento");
    if (response.sucesso) {
      const dados = response.dados;
      setOptionsFormasPagamento(
        dados.map((item) => ({
          label: `${item?.nome}`,
          value: `${item?.id}`,
        }))
      );
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  const cadastrarCliente = async () => {
    setLoadTable(true);

    if (!cliente) {
      const cidadeCliente = cidade + " - " + ufCliente;

      const data = {
        nome: nomeMask(nome),
        cpf_cnpj: perfil === "fisica" ? cpf : cnpj,
        email,
        telefone,
        perfil,
        endereco,
        cep,
        numero,
        bairro,
        cidade: cidadeCliente,
        complemento,
      };

      const response = await apiClient.post("/clientes", data);
      if (response.sucesso) {
        setCliente(response.dados);
        await cadastrarVeiculo(response.dados);
      } else {
        console.error(response.mensagem);
        if (response.mensagem !== "") toast.error(response.mensagem);
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }
    } else await cadastrarVeiculo(cliente);

    setLoadTable(false);
  };

  const calcularTotalAdd = (servicosIdsPlano, servico) => {
    const qtdPlano = servicosIdsPlano.filter((serv) => {
      return parseInt(serv) === parseInt(servico.value);
    }).length;
    const descontoPlano = servico.preco * qtdPlano;

    let totalAnteriorDeIncidenciasDesteServico = servicosSelecionados.filter(
      (serv) => parseInt(serv.value) === parseInt(servico.value)
    ).length;

    let totalAnteriorDesteServico =
      servico.preco * totalAnteriorDeIncidenciasDesteServico - descontoPlano;
    totalAnteriorDesteServico =
      totalAnteriorDesteServico < 0 ? 0 : totalAnteriorDesteServico;

    const newServicos = [...servicosSelecionados, servico];

    const totalDeIncidenciasDesteServico = newServicos.filter(
      (serv) => parseInt(serv.value) === parseInt(servico.value)
    ).length;

    let totalDesteServico =
      servico.preco * totalDeIncidenciasDesteServico - descontoPlano;
    totalDesteServico = totalDesteServico < 0 ? 0 : totalDesteServico;

    const descontoOS = (desconto === "" ? 0 : parseFloat(desconto)) / 100;
    let totalCalculado = total - totalAnteriorDesteServico + totalDesteServico;
    totalCalculado = totalCalculado < 0 ? 0 : totalCalculado;

    setTotal(totalCalculado);
    setTotalDescontado(totalCalculado - totalCalculado * descontoOS);

    setServicosSelecionados(newServicos);
  };

  const calcularTotalRemover = (servicosIdsPlano, index) => {
    const newServicos = [...servicosSelecionados];
    const servicoRemovido = newServicos.splice(index, 1)[0];

    const qtdPlano = servicosIdsPlano.filter(
      (serv) => parseInt(serv) === parseInt(servicoRemovido.value)
    ).length;
    const descontoPlano = servicoRemovido.preco * qtdPlano;

    let totalAnteriorDeIncidenciasDesteServico = servicosSelecionados.filter(
      (serv) => parseInt(serv.value) === parseInt(servicoRemovido.value)
    ).length;

    let totalAnteriorDesteServico =
      servicoRemovido.preco * totalAnteriorDeIncidenciasDesteServico -
      descontoPlano;
    totalAnteriorDesteServico =
      totalAnteriorDesteServico < 0 ? 0 : totalAnteriorDesteServico;

    const totalDeIncidenciasDesteServico = newServicos.filter(
      (serv) => parseInt(serv.value) === parseInt(servicoRemovido.value)
    ).length;
    let totalDesteServico =
      servicoRemovido.preco * totalDeIncidenciasDesteServico - descontoPlano;
    totalDesteServico = totalDesteServico < 0 ? 0 : totalDesteServico;

    const descontoOS = (desconto === "" ? 0 : parseFloat(desconto)) / 100;
    let totalCalculado = total - totalAnteriorDesteServico + totalDesteServico;
    totalCalculado = totalCalculado < 0 ? 0 : totalCalculado;
    setTotal(totalCalculado);
    setTotalDescontado(totalCalculado - totalCalculado * descontoOS);
    setServicosSelecionados(newServicos);
  };

  const criar = async (cliente, veiculo) => {
    setLoadTable(true);

    const servicosIds = servicosSelecionados.map((servico) => servico.value);

    const data = {
      cliente_id: cliente.id,
      veiculo_id: veiculo.id,
      filial_id: filial.value,
      expediente_id: expediente?.value,
      descricao,
      desconto: !desconto || desconto === "" ? 0 : desconto,
      data_realizado: dataRealizado,
      total: !total || total === "" ? 0 : total,
      forma_pagamento_id: formaPagamento.value,
      servicos_ids: servicosIds,
      status: statusOrdem,
      servicos_adicionais: servicosAdicionais,
    };

    const response = await apiClient.post("/ordens-servico", data);
    if (response.sucesso) {
      toast.success(response.mensagem);

      //redirecionar para /ordens-servico
      window.location.href = "/ordens-servico";
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoadTable(false);
  };

  const fetchClientes = useCallback(async (termoBusca) => {
    setLoadTable(true);
    const data = {
      itens_pagina: 10,
      pagina: 1,
      termo: termoBusca,
      order_by: "created_at",
      order: "desc",
    };

    const response = await apiClient.post("/clientes/busca", data);
    if (response.sucesso) {
      const dados = response.dados;
      setClientes(dados.data);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoadTable(false);
  }, []);

  useEffect(() => {
    const fetchAllCallbacks = async () => {
      await fetchFiliais();
      await fetchExpedientes();
      await fetchFormasPagamento();
      await fetchServicos();
      document.getElementById("placa")?.focus();
    };
    const buscaClientes = async () => {
      await fetchClientes(termoBusca);
    };
    if (loading) {
      if (optionsFiliais.length === 0) fetchAllCallbacks();
      if (termoBusca !== "") buscaClientes(termoBusca);
      else {
        setLoading(false);
        setLoadTable(false);
      }
    }

    if(dataRealizado===""){
      /// colocar a data de hoje, no formato aaaa-mm-dd
      const data = new Date();
      const ano = data.getFullYear();
      const mes = data.getMonth() + 1;
      const dia = data.getDate();
      const dataFormatada = `${ano}-${mes.toString().padStart(2, "0")}-${dia
        .toString()
        .padStart(2, "0")}`;
      setDataRealizado(dataFormatada);
    }
    //se expediente não tiver sido selecionado, selecionar o primeiro
    if (!expediente) setExpediente(optionsExpedientes[0]);
  }, [termoBusca, loading]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Cadastro de Ordens de Serviço"
            pageTitle="Ordens de Serviço"
          />
          <Row>
            <Col md="8">
              <Card>
                <CardBody>
                  <div className="live-preview">
                    <div className="position-relative m-4">
                      <Progress
                        value={etapa === 1 ? 0 : etapa === 2 ? 50 : 100}
                        style={{ height: "1px" }}
                      />
                      <Button
                        size="sm"
                        color={etapa === 1 ? "primary" : "success"}
                        className="position-absolute top-0 start-0 translate-middle rounded-pill"
                        style={{ width: "2rem", height: "2rem" }}
                      >
                        1
                      </Button>
                      <Button
                        size="sm"
                        color={
                          etapa === 1
                            ? "light"
                            : etapa === 2
                            ? "primary"
                            : "success"
                        }
                        className="position-absolute top-0 start-50 translate-middle rounded-pill"
                        style={{ width: "2rem", height: "2rem" }}
                      >
                        2
                      </Button>
                      <Button
                        size="sm"
                        color={
                          etapa === 1
                            ? "light"
                            : etapa === 2
                            ? "light"
                            : "primary"
                        }
                        className="position-absolute top-0 start-100 translate-middle rounded-pill"
                        style={{ width: "2rem", height: "2rem" }}
                      >
                        3
                      </Button>
                    </div>
                  </div>
                  {etapa === 1 ? (
                    <>
                      <Row className="mt-3">
                        <Col md={3}></Col>
                        <Col md={6}>
                          <Label for="placa" className="form-label">
                            Placa
                          </Label>
                          <Input
                            type="text"
                            id="placa"
                            name="placa"
                            placeholder="Digite a placa do veículo"
                            value={placa || ""}
                            onChange={(e) => formatPlaca(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") fetchCarroDados();
                            }}
                            maxLength={7}
                          />
                        </Col>
                        <Col md={3}></Col>
                      </Row>
                      <div className="hstack gap-2 justify-content-center mt-5">
                        <button
                          className="btn btn-primary"
                          onClick={() => fetchCarroDados()}
                          disabled={loadTable}
                        >
                          {loadTable && (
                            <Spinner size="sm" color="light" className="me-2" />
                          )}
                          Pesquisar Veículo
                        </button>
                      </div>
                    </>
                  ) : etapa === 2 ? (
                    <>
                      <Row className="mt-3">
                        <Col md={6}>
                          <Row className="mt-3">
                            <Col md="6">
                              <Label for="placa" className="form-label">
                                Placa
                              </Label>
                              <Input
                                type="text"
                                id="placa"
                                name="placa"
                                placeholder="Digite a placa do veículo"
                                value={placa || ""}
                                maxLength={7}
                                readOnly
                              />
                            </Col>
                            <Col md="6">
                              <Label className="form-label"></Label>
                              <Button
                                className="btn btn-primary mt-4"
                                onClick={() => fetchCarroDados(placa)}
                                disabled={loadTable}
                              >
                                {loadTable && (
                                  <Spinner
                                    size="sm"
                                    color="light"
                                    className="me-2"
                                  />
                                )}
                                Consultar
                              </Button>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md="6">
                              <Label for="marca" className="form-label">
                                Marca
                              </Label>
                              <Input
                                type="text"
                                id="marca"
                                name="marca"
                                value={marca || ""}
                                readOnly
                              />
                            </Col>
                            <Col md="6">
                              <Label for="modelo" className="form-label">
                                Modelo
                              </Label>
                              <Input
                                type="text"
                                id="modelo"
                                name="modelo"
                                value={modelo || ""}
                                readOnly
                              />
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md="6">
                              <Label for="submodelo" className="form-label">
                                Submodelo
                              </Label>
                              <Input
                                type="text"
                                id="submodelo"
                                name="submodelo"
                                value={submodelo || ""}
                                readOnly
                              />
                            </Col>
                            <Col md="6">
                              <Label for="versao" className="form-label">
                                Versão
                              </Label>
                              <Input
                                type="text"
                                id="versao"
                                name="versao"
                                value={versao || ""}
                                readOnly
                              />
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md="6">
                              <Label for="cor" className="form-label">
                                Cor
                              </Label>
                              <Input
                                type="text"
                                id="cor"
                                name="cor"
                                value={cor || ""}
                                readOnly
                              />
                            </Col>
                            <Col md="6">
                              <Label for="ano" className="form-label">
                                Ano
                              </Label>
                              <Input
                                type="text"
                                id="ano"
                                name="ano"
                                value={ano || ""}
                                readOnly
                              />
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md="6">
                              <Label for="anoModelo" className="form-label">
                                Ano Modelo
                              </Label>
                              <Input
                                type="text"
                                id="anoModelo"
                                name="anoModelo"
                                value={anoModelo || ""}
                                readOnly
                              />
                            </Col>
                            <Col md="6">
                              <Label for="situacao" className="form-label">
                                Situação
                              </Label>
                              <Input
                                type="text"
                                id="situacao"
                                name="situacao"
                                value={situacao || ""}
                                readOnly
                              />
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md="6">
                              <Label for="origem" className="form-label">
                                Origem
                              </Label>
                              <Input
                                type="text"
                                id="origem"
                                name="origem"
                                value={origem || ""}
                                readOnly
                              />
                            </Col>
                            <Col md="6">
                              <Label for="municipio" className="form-label">
                                Município
                              </Label>
                              <Input
                                type="text"
                                id="municipio"
                                name="municipio"
                                value={municipio || ""}
                                readOnly
                              />
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md="6">
                              <Label for="uf" className="form-label">
                                UF
                              </Label>
                              <Input
                                type="text"
                                id="uf"
                                name="uf"
                                value={uf || ""}
                                readOnly
                              />
                            </Col>
                            <Col md="6" className="justify-content-center">
                              <img
                                src={logo}
                                alt="Logo"
                                className="img-fluid"
                                style={{ maxHeight: "100px" }}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md={6}>
                          {!cliente && (
                            <Row className="mt-5">
                              <Col md="12">
                                <div>
                                  <Label className="form-label">
                                    Pesquisar Cliente
                                  </Label>
                                  <SearchOption
                                    setTermoBusca={setTermoBusca}
                                    setPaginaAtual={setPaginaAtual}
                                    termoBusca={termoBusca}
                                    setLoading={setLoading}
                                    setLoadTable={setLoadTable}
                                  />
                                </div>
                              </Col>
                            </Row>
                          )}
                          {loadTable ? (
                            <Spinner size="sm" color="light" className="me-2" />
                          ) : !cliente && termoBusca !== "" ? (
                            // tabela de clientes para escolher
                            <table className="table table-hover table-striped">
                              <thead>
                                <tr>
                                  <th>Nome</th>
                                  <th>CPF/CNPJ</th>
                                  <th>Ações</th>
                                </tr>
                              </thead>
                              <tbody>
                                {clientes.map((cliente) => (
                                  <tr key={cliente.id}>
                                    <td>{cliente.nome}</td>
                                    <td>{cliente.cpf_cnpj}</td>
                                    <td>
                                      <button
                                        className="btn btn-sm btn-primary"
                                        onClick={() => chooseCliente(cliente)}
                                      >
                                        Selecionar
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <>
                              <Row className="mt-5">
                                <Col md="6">
                                  <div>
                                    <Label
                                      htmlFor="perfil"
                                      className="form-label"
                                    >
                                      Perfil{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      id="perfil"
                                      className="form-control"
                                      type="select"
                                      value={perfil}
                                      onChange={(e) =>
                                        setPerfil(e.target.value)
                                      }
                                    >
                                      <option value="fisica">
                                        Pessoa Física
                                      </option>
                                      <option value="juridica">
                                        Pessoa Jurídica
                                      </option>
                                    </Input>
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div>
                                    <Label
                                      htmlFor="nome"
                                      className="form-label"
                                    >
                                      Nome{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      id="nome"
                                      className="form-control"
                                      type="text"
                                      placeholder="Nome"
                                      value={nome || ""}
                                      onChange={(e) => setNome(e.target.value)}
                                      maxLength={150}
                                      disabled={!cliente ? false : true}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="mt-3">
                                <Col md="6">
                                  <div>
                                    <Label
                                      htmlFor="telefone"
                                      className="form-label"
                                    >
                                      Telefone{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <InputMask
                                      mask="(99) 99999-9999"
                                      placeholder="(11) 99999-9999"
                                      value={telefone || ""}
                                      onChange={(e) =>
                                        setTelefone(e.target.value)
                                      }
                                    >
                                      {(inputProps) => (
                                        <Input
                                          type="text"
                                          className="form-control"
                                          {...inputProps}
                                        />
                                      )}
                                    </InputMask>
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div>
                                    <Label
                                      htmlFor="email"
                                      className="form-label"
                                    >
                                      E-mail
                                    </Label>
                                    <Input
                                      id="email"
                                      className="form-control"
                                      type="email"
                                      placeholder="Email"
                                      value={email || ""}
                                      onChange={(e) =>
                                        formatEmail(e.target.value)
                                      }
                                      maxLength={150}
                                      disabled={!cliente ? false : true}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                {perfil === "fisica" ? (
                                  <Col md="6">
                                    <div>
                                      <Label
                                        htmlFor="cpf"
                                        className="form-label"
                                      >
                                        CPF
                                      </Label>
                                      <InputMask
                                        mask="999.999.999-99"
                                        placeholder="999.999.999-99"
                                        value={cpf || ""}
                                        onChange={(e) => setCpf(e.target.value)}
                                        disabled={!cliente ? false : true}
                                      >
                                        {(inputProps) => (
                                          <Input
                                            type="text"
                                            className="form-control"
                                            {...inputProps}
                                          />
                                        )}
                                      </InputMask>
                                    </div>
                                  </Col>
                                ) : (
                                  <Col md="6">
                                    <div>
                                      <Label
                                        htmlFor="cnpj"
                                        className="form-label"
                                      >
                                        CNPJ
                                      </Label>
                                      <InputMask
                                        mask="99.999.999/9999-99"
                                        placeholder="99.999.999/9999-99"
                                        value={cnpj || ""}
                                        onChange={(e) =>
                                          setCnpj(e.target.value)
                                        }
                                        disabled={!cliente ? false : true}
                                      >
                                        {(inputProps) => (
                                          <Input
                                            type="text"
                                            className="form-control"
                                            {...inputProps}
                                          />
                                        )}
                                      </InputMask>
                                    </div>
                                  </Col>
                                )}
                                <Col md="6">
                                  <div>
                                    <Label htmlFor="cep" className="form-label">
                                      CEP
                                    </Label>
                                    <InputMask
                                      mask="99999-999"
                                      placeholder="00000-000"
                                      value={cep || ""}
                                      onBlur={(e) => handleCep(e.target.value)}
                                      onChange={(e) => setCep(e.target.value)}
                                      disabled={!cliente ? false : true}
                                    >
                                      {(inputProps) => (
                                        <Input
                                          type="text"
                                          className="form-control"
                                          {...inputProps}
                                        />
                                      )}
                                    </InputMask>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <Col md="6">
                                  <div>
                                    <Label
                                      htmlFor="endereco"
                                      className="form-label"
                                    >
                                      Endereço
                                    </Label>
                                    <Input
                                      id="endereco"
                                      className="form-control"
                                      type="text"
                                      placeholder="Endereço"
                                      value={endereco || ""}
                                      onChange={(e) =>
                                        setEndereco(e.target.value)
                                      }
                                      maxLength={255}
                                      disabled={!cliente ? false : true}
                                    />
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div>
                                    <Label
                                      htmlFor="numero"
                                      className="form-label"
                                    >
                                      Número
                                    </Label>
                                    <Input
                                      id="numero"
                                      className="form-control"
                                      type="text"
                                      placeholder="Número"
                                      value={numero || ""}
                                      onChange={(e) =>
                                        setNumero(
                                          e.target.value.replace(/\D/g, "")
                                        )
                                      }
                                      maxLength={8}
                                      disabled={!cliente ? false : true}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <Col md="6">
                                  <div>
                                    <Label
                                      htmlFor="bairro"
                                      className="form-label"
                                    >
                                      Bairro
                                    </Label>
                                    <Input
                                      id="bairro"
                                      className="form-control"
                                      type="text"
                                      placeholder="Bairro"
                                      value={bairro || ""}
                                      onChange={(e) =>
                                        setBairro(e.target.value)
                                      }
                                      maxLength={150}
                                      disabled={!cliente ? false : true}
                                    />
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div>
                                    <Label
                                      htmlFor="cidade"
                                      className="form-label"
                                    >
                                      Cidade
                                    </Label>
                                    <Input
                                      id="cidade"
                                      className="form-control"
                                      type="text"
                                      placeholder="Cidade"
                                      value={cidade || ""}
                                      onChange={(e) =>
                                        setCidade(e.target.value)
                                      }
                                      maxLength={150}
                                      disabled={!cliente ? false : true}
                                    />
                                  </div>
                                </Col>
                                <Col md="12" className="mt-3">
                                  <div>
                                    <Label
                                      htmlFor="complemento"
                                      className="form-label"
                                    >
                                      Complemento
                                    </Label>
                                    <Input
                                      id="complemento"
                                      className="form-control"
                                      type="text"
                                      placeholder="Complemento"
                                      value={complemento || ""}
                                      onChange={(e) =>
                                        setComplemento(e.target.value)
                                      }
                                      maxLength={255}
                                      disabled={!cliente ? false : true}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </>
                          )}

                          <div className="hstack gap-2 justify-content-center mt-5">
                            {!cliente ? (
                              <button
                                className="btn btn-primary"
                                onClick={() => cadastrarCliente()}
                                disabled={loadTable}
                              >
                                Cadastrar Cliente e Veículo
                              </button>
                            ) : (
                              <button
                                className="btn btn-success"
                                onClick={() => cadastrarVeiculo(cliente)}
                              >
                                Adicionar Veículo ao Cliente
                              </button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row className="mt-3">
                        <Col md={6}>
                          <Row className="mt-3">
                            <Col md="6">
                              <Label for="placa" className="form-label">
                                Placa
                              </Label>
                              <Input
                                type="text"
                                id="placa"
                                name="placa"
                                value={placa || ""}
                                readOnly
                              />
                            </Col>
                            <Col md="6">
                              <Label for="marca" className="form-label">
                                Marca
                              </Label>
                              <Input
                                type="text"
                                id="marca"
                                name="marca"
                                value={marca || ""}
                                readOnly
                              />
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md="6">
                              <Label for="modelo" className="form-label">
                                Modelo
                              </Label>
                              <Input
                                type="text"
                                id="modelo"
                                name="modelo"
                                value={modelo || ""}
                                readOnly
                              />
                            </Col>
                            <Col md="6">
                              <Label for="cor" className="form-label">
                                Cor
                              </Label>
                              <Input
                                type="text"
                                id="cor"
                                name="cor"
                                value={cor || ""}
                                readOnly
                              />
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md="6">
                              <Label for="cliente" className="form-label">
                                Cliente
                              </Label>
                              <Input
                                type="text"
                                id="cliente"
                                name="cliente"
                                value={nome || ""}
                                readOnly
                              />
                            </Col>
                            <Col md="6">
                              <Label
                                htmlFor="statusOrdem"
                                className="form-label"
                              >
                                Status da Ordem de Serviço
                              </Label>
                              <Input
                                id="statusOrdem"
                                className="form-control"
                                type="select"
                                value={statusOrdem}
                                onChange={(e) => setStatusOrdem(e.target.value)}
                              >
                                <option value="novo">Novo</option>
                                <option value="pago">Pago</option>
                                <option value="em-andamento">
                                  Em Andamento
                                </option>
                                <option value="inadimplente">
                                  Inadimplente
                                </option>
                                <option value="finalizada">Finalizado</option>
                              </Input>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md="9">
                              <Label htmlFor="servicos" className="form-label">
                                Serviços
                              </Label>
                              <Select
                                value={servico}
                                onChange={(sortBy) => {
                                  setServico(sortBy);
                                }}
                                options={optionsServicos}
                                id="servicos"
                                placeholder={
                                  loadTable
                                    ? "Carregando..."
                                    : "Selecione o serviço"
                                }
                                disabled={loadTable}
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    border: "1px solid #ced4da",
                                    color: "#aaa",
                                  }),
                                  input: (provided) => ({
                                    ...provided,
                                    color: "#aaa",
                                  }),
                                }}
                              />
                            </Col>
                            <Col md="3" className="pt-2">
                              <button
                                className="btn btn-sm btn-success mt-4"
                                onClick={() => {
                                  if (servico?.value) {
                                    calcularTotalAdd(
                                      cliente?.servicos_ids,
                                      servico
                                    );
                                    setServico({});
                                  }
                                }}
                              >
                                Adicionar
                              </button>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={6}>
                          <Row className="mt-3">
                            <Col md="6">
                              <Label for="filial" className="form-label">
                                Filial
                              </Label>
                              <Select
                                value={filial}
                                onChange={(sortBy) => {
                                  setFilial(sortBy);
                                }}
                                options={optionsFiliais}
                                id="filial"
                                placeholder={
                                  loadTable
                                    ? "Carregando..."
                                    : "Selecione a filial"
                                }
                                disabled={loadTable}
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    border: "1px solid #ced4da",
                                    color: "#aaa",
                                  }),
                                  input: (provided) => ({
                                    ...provided,
                                    color: "#aaa",
                                  }),
                                }}
                              />
                            </Col>
                            <Col md="6">
                              <Label for="expediente" className="form-label">
                                Expediente
                              </Label>
                              <Select
                                value={expediente || {}}
                                onChange={(sortBy) => {
                                  setExpediente(sortBy);
                                }}
                                options={optionsExpedientes}
                                id="expediente"
                                placeholder={
                                  loadTable
                                    ? "Carregando..."
                                    : "Selecione o expediente"
                                }
                                disabled={loadTable}
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    border: "1px solid #ced4da",
                                    color: "#aaa",
                                  }),
                                  input: (provided) => ({
                                    ...provided,
                                    color: "#aaa",
                                  }),
                                }}
                              />
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md="12">
                              <Label for="descricao" className="form-label">
                                Observação
                              </Label>
                              <Input
                                type="textarea"
                                id="descricao"
                                name="descricao"
                                value={descricao || ""}
                                onChange={(e) => setDescricao(e.target.value)}
                              />
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md="6">
                              <Label for="dataRealizado" className="form-label">
                                Data Realizado
                              </Label>
                              <Input
                                type="date"
                                id="dataRealizado"
                                name="dataRealizado"
                                value={dataRealizado}
                                onChange={(e) =>
                                  setDataRealizado(e.target.value)
                                }
                              />
                            </Col>
                            <Col md="6">
                              <Label for="total" className="form-label">
                                Total
                              </Label>
                              <NumericFormat
                                id="total"
                                placeholder="R$ 0,00"
                                className="form-control"
                                value={totalDescontado || "0"}
                                onValueChange={() => {}}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                prefix="R$ "
                                fixedDecimalScale={true}
                                allowNegative={false}
                                readOnly
                              />
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md="6">
                              <Label for="desconto" className="form-label">
                                Desconto
                              </Label>
                              <NumericFormat
                                id="desconto"
                                placeholder="0,00 %"
                                className="form-control"
                                value={desconto || ""}
                                onValueChange={({ value }) => {
                                  setDesconto(value);
                                  setTotalDescontado(
                                    total *
                                      (1 -
                                        parseFloat(value === "" ? "0" : value) /
                                          100)
                                  );
                                }}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                suffix=" %"
                                fixedDecimalScale={true}
                                allowNegative={false}
                              />
                            </Col>
                            <Col md="6">
                              <Label
                                for="formaPagamento"
                                className="form-label"
                              >
                                Forma de Pagamento
                              </Label>
                              <Select
                                value={formaPagamento}
                                onChange={(sortBy) => {
                                  setFormaPagamento(sortBy);
                                }}
                                options={optionsFormasPagamento}
                                id="formaPagamento"
                                placeholder={
                                  loadTable
                                    ? "Carregando..."
                                    : "Selecione a forma de pagamento"
                                }
                                disabled={loadTable}
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    border: "1px solid #ced4da",
                                    color: "#aaa",
                                  }),
                                  input: (provided) => ({
                                    ...provided,
                                    color: "#aaa",
                                  }),
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row className="mt-3 border-top pt-3">
                        <h4>Serviços Adicionais</h4>
                        <Col md="5">
                          <Label for="descricao" className="form-label">
                            Descrição
                          </Label>
                          <Input
                            type="text"
                            id="descricao"
                            name="descricao"
                            value={descricaoAdicional || ""}
                            onChange={(e) =>
                              setDescricaoAdicional(e.target.value)
                            }
                          />
                        </Col>
                        <Col md="5">
                          <Label for="valorAdicional" className="form-label">
                            Valor
                          </Label>
                          <NumericFormat
                            id="valorAdicional"
                            placeholder="R$ 0,00"
                            className="form-control"
                            value={valorAdicional || ""}
                            onValueChange={({ value }) =>
                              setValorAdicional(value)
                            }
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            prefix="R$ "
                            fixedDecimalScale={true}
                            allowNegative={false}
                          />
                        </Col>
                        <Col md="2" className="pt-2">
                          <button
                            className="btn btn-sm btn-success mt-4"
                            onClick={() => {
                              addServicoAdicional();
                            }}
                          >
                            Adicionar
                          </button>
                        </Col>
                      </Row>
                      <Row className="mt-3 border-top pt-3">
                        <Col md="12">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>Serviço</th>
                                <th>Preço</th>
                                <th>Remover</th>
                              </tr>
                            </thead>
                            <tbody>
                              {servicosSelecionados.map((servico, index) => (
                                <tr key={index}>
                                  <td
                                    style={{
                                      overflowWrap: "break-word",
                                      whiteSpace: "pre-wrap",
                                    }}
                                  >
                                    {servico.nome}

                                    {quantidadeEmPlano(
                                      servico,
                                      cliente?.servicos
                                    ) > 0 && (
                                      <p className="m-0">
                                        <p className="badge bg-light text-primary me-1">
                                          Presente no plano do cliente
                                        </p>
                                      </p>
                                    )}
                                  </td>
                                  <td>{formatCurrency(servico.preco)}</td>
                                  <td>
                                    <button
                                      className="btn btn-sm btn-danger"
                                      onClick={() => {
                                        calcularTotalRemover(
                                          cliente?.servicos_ids,
                                          index
                                        );
                                      }}
                                    >
                                      Remover
                                    </button>
                                  </td>
                                </tr>
                              ))}
                              {servicosAdicionais.map((servico, index) => (
                                <tr key={index}>
                                  <td
                                    style={{
                                      overflowWrap: "break-word",
                                      whiteSpace: "pre-wrap",
                                    }}
                                  >
                                    {servico.descricao}
                                  </td>
                                  <td>{formatCurrency(servico.preco)}</td>
                                  <td>
                                    <button
                                      className="btn btn-sm btn-danger"
                                      onClick={() => {
                                        removeServicoAdicional(servico.index);
                                      }}
                                    >
                                      Remover
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Col>
                      </Row>

                      <div className="hstack gap-2 justify-content-center mt-5">
                        <button
                          className="btn btn-success"
                          onClick={() => criar(cliente, veiculo)}
                          disabled={loadTable}
                        >
                          {loadTable && (
                            <Spinner size="sm" color="info" className="me-2" />
                          )}
                          Criar
                        </button>
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
            {cliente && (
              <Col md="4">
                <Card>
                  <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Planos</h4>
                  </CardHeader>
                  <CardBody>
                    <div className="mt-4">
                      {!cliente?.planos || cliente?.planos.length === 0 ? (
                        <Row className="text-muted text-center">
                          <small>Nenhum plano ativo</small>
                        </Row>
                      ) : (
                        cliente.planos.map((plano) => (
                          <div
                            key={plano.id}
                            className="d-flex justify-content-between mt-2"
                          >
                            <div>
                              <h5 className="mb-0 text-info">{plano.nome}</h5>
                              <p className="mb-0">
                                <b>Ativo até:</b>{" "}
                                {converterData(plano?.expiracao, false)}
                              </p>
                            </div>
                            <div>{formatCurrency(plano.preco)}</div>
                          </div>
                        ))
                      )}
                    </div>
                  </CardBody>
                </Card>
                <Card className="mt-3">
                  <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">
                      Serviços Ativos no Cliente
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <div className="mt-3">
                      {!cliente?.servicos || cliente?.servicos.length === 0 ? (
                        <Row className="text-muted text-center">
                          <small>Nenhum serviço ativo</small>
                        </Row>
                      ) : (
                        cliente.servicos.map((servico) => (
                          <div
                            key={servico.id}
                            className="d-flex justify-content-between mt-2"
                          >
                            <div>
                              <h5 className="mb-0 text-primary">
                                {servico.servico?.servico}
                              </h5>
                              <p className="mb-0">
                                <b>Ativo até:</b>{" "}
                                {servico.plano_id > 0
                                  ? converterData(servico?.expiracao, false)
                                  : "Sem expiração"}
                              </p>
                            </div>
                            <div>
                              {servico.plano_id > 0 ? (
                                <button
                                  disabled
                                  className="btn btn-sm btn-secondary"
                                >
                                  Plano
                                </button>
                              ) : (
                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={() => {
                                    excluir(servico);
                                  }}
                                  disabled={loadTable}
                                >
                                  Remover
                                </button>
                              )}
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default ModalNovo;
