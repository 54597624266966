import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Card,
  CardHeader,
  CardBody,
  Button,
  Spinner,
} from "reactstrap";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";

import SearchModal from "./SearchModal";

import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalTransferirVeiculo = ({
  modal,
  setModal,
  cliente,
  veiculo,
  setRefresh,
}) => {
  const [novoCliente, setNovoCliente] = useState("");
  const [clientes, setClientes] = useState([]);
  const [totalDeClientes, setTotalDeClientes] = useState(0);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [termo, setTermo] = useState("");
  const [loadTable, setLoadTable] = useState(false);
  const [loading, setLoading] = useState(false);

  const clientesPorPagina = 5;

  const toggle = () => {
    setNovoCliente("");
    setClientes([]);

    setModal(!modal);
  };

  const transferirVeiculo = async (veiculo, cliente) => {
    const data = {
      cliente_id: cliente.id,
    };

    const response = await apiClient.put(
      `/veiculos/${veiculo.id}/transferir`,
      data
    );
    if (response.sucesso) {
      toast.success("Veículo transferido com sucesso!");
      setRefresh(true);
      toggle();
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }
    }
  };

  const fetchClientes = useCallback(async (paginaAtual, termoBusca) => {
    setLoadTable(true);
    const data = {
      itens_pagina: clientesPorPagina,
      pagina: paginaAtual,
      termo: termoBusca,
      order_by: "created_at",
      order: "desc",
    };

    const response = await apiClient.post("/clientes/busca", data);
    if (response.sucesso) {
      const dados = response.dados;
      setClientes(dados.data);
      setTotalDeClientes(dados.total);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoadTable(false);
  }, []);

  useEffect(() => {
    const fetch = async () => {
      await fetchClientes(paginaAtual, termo);
    };

    if (modal && veiculo) {
      fetch();
    }
  }, [modal, veiculo, paginaAtual, termo]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Transferir Veículo
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <p className="mb-4 text-muted">
          <b>Cliente</b>: {cliente?.nome}
        </p>
        <Card>
          <CardHeader>
            <SearchModal
              setTermoBusca={setTermo}
              termoBusca={termo}
              setLoading={setLoading}
              setPaginaAtual={setPaginaAtual}
            />
          </CardHeader>
          <CardBody>
            <Row className="g-4 align-items-center pb-3">
              <div className="col-sm">
                <div>
                  <h5 className="card-title mb-0">Clientes</h5>
                </div>
              </div>
            </Row>
            <div className="live-preview">
              {loadTable ? (
                <Row className="justify-content-center">
                  <Spinner />
                </Row>
              ) : (
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="border-0">Nome</th>
                      <th className="border-0">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clientes?.map((cliente, index) => {
                      return (
                        <tr key={index}>
                          <td>{cliente?.nome}</td>
                          <td>
                            <Link
                              to="#"
                              className="btn btn-sm btn-success"
                              onClick={() => {
                                setNovoCliente(cliente);
                              }}
                            >
                              Selecionar
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
            <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
              <div className="col-sm-auto mt-3 mt-sm-0">
                <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                  <li
                    className={`page-item ${
                      paginaAtual === 1 ? "disabled" : ""
                    }`}
                  >
                    <Link
                      to="#"
                      className="page-link"
                      onClick={() => setPaginaAtual(paginaAtual - 1)}
                    >
                      ←
                    </Link>
                  </li>
                  <li
                    className={`page-item ${
                      paginaAtual === clientesPorPagina ? "disabled" : ""
                    }`}
                  >
                    <Link
                      to="#"
                      className="page-link"
                      onClick={() => setPaginaAtual(paginaAtual + 1)}
                    >
                      →
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </CardBody>
        </Card>
        <div className="hstack gap-2 justify-content-end mt-5">
          <Link
            to="#"
            className="btn btn-link link-success fw-medium"
            onClick={() => toggle()}
          >
            <i className="ri-close-line me-1 align-middle"></i> Fechar
          </Link>
          <Button
            className="btn btn-success"
            onClick={() => transferirVeiculo(veiculo, novoCliente)}
            disabled={loadTable}
          >
            Mudar Dono
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalTransferirVeiculo;
